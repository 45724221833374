import { Box, Container } from "@mui/material";

const WatchFacesPrivacy = () => {
    return <Container>
        <h1>Watch Faces Privacy Policy</h1>
        <Box>
            <p>Last updated: December 10, 2022</p>
            <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the the Watch Faces and tells You about Your privacy rights and how the law protects You.</p>
            <p>By downloading and using the Watch Faces, You agree to the collection and use of information in accordance with this Privacy Policy</p>
            <h1>Collecting and Using Your Personal Data</h1>
            <p>We do not use or upload Your Personal data like health data or location to any service through Watch Faces and will be only used locally and just to show that information to you. </p>

            <h3>Usage Data</h3>
            <p>Some Data may be collected automatically through Google Play Services but they are following the Google Play Services Policy and they don't have Personal Identification Information.
            </p>
            <h2>Security of Your Personal Data</h2>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
            <h1>Children's Privacy</h1>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
            <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
            <h1>Changes to this Privacy Policy</h1>
            <p>We may update Our Privacy Policy from time to time. As we don not any contact information for Watch Faces we cannot notify You of any changes in the Privacy Policy on this page.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
        </Box>
        <br />
        <br />
    </Container>
};

export default WatchFacesPrivacy;
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const Home = () => {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center"
        }} pt={2}>
            
            <Card sx={{ maxWidth: 400 }}>
            <CardMedia
                component="img"
                image="/assets/images/avatar/profile.jpg"
                alt="Mohsen Beiranvand"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    Mohsen Beiranvand
                </Typography>
                <Typography variant="body2" color="text.secondary" >
                I'm an innovative software developer who has more than 7 years of experience in Android development. 
                I care about quality, not only in product but also in code that I share with my teammates. 
                I also try to increase readability, reliability, and scalability by using design principles and writing good test cases.
                I enjoy working as part of a team. I'm ably collaborating with cross-functional teams to develop creative features.
                </Typography>
            </CardContent>
            <CardActions>
                <Button href="https://www.linkedin.com/in/imohsenb" size="small" color="secondary">Learn More</Button>
            </CardActions>
        </Card>
        </Box>
    )
};

export default Home;
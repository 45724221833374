
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { themeOptions } from './config/theme';
import Box from '@mui/material/Box';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import WatchFaces from './pages/WatchFaces';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import WatchFacesPrivacy from './pages/WatchFaces/privacy';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC45W_GcZPxT0-hU6Yjvkw5kOO-yHhM88M",
  authDomain: "imohsenb-996bb.firebaseapp.com",
  projectId: "imohsenb-996bb",
  storageBucket: "imohsenb-996bb.appspot.com",
  messagingSenderId: "689034675840",
  appId: "1:689034675840:web:1399dce4713e82cca3c865",
  measurementId: "G-VBSY44RM4E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const theme = createTheme(themeOptions);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="watchfaces/*" element={<WatchFaces />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

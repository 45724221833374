
export const themeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: '#51443a',
      text: {
        primary: '#ffffff',
        secondary: '#ffdcc0',
      },
    },
    secondary: {
      main: '#f5b200',
      text: {
        primary: '#ffffff',
        secondary: '#ffdcc0',
      },
    },
    background: {
      default: '#201b17',
      paper: '#59422d',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffdcc0',
    },
  },
};
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WatchFacesPrivacy from "./privacy";
import WatchFacesHome from "./watchfaces";

const WatchFaces = () => {
  return (
    <Routes>
      <Route index element={<WatchFacesHome />} />
      <Route path="privacy" element={<WatchFacesPrivacy />} />
    </Routes>
  )
};

export default WatchFaces;